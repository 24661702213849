import React from 'react';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <>
      {/* // <footer className="bg-naviblue p-4 text-white">
    //   <div className="container mx-auto text-center">
    //     <div className="flex justify-center space-x-4 mb-4">
    //       <a href="#">Resources</a>
    //       <a href="#">Information</a>
    //     </div>
    //     <p>
    //       &copy; 2024 Doctor Info Listing Center. All rights reserved. @{' '}
    //       <a href="https://r4healthcare.in/" target="_blank" rel="noreferrer">
    //         R4 Healthcare
    //       </a>
    //     </p>
    //   </div>
    // </footer> */}

      <footer className="bg-customBlue bg-opacity-50">
        {/* Footer Top */}
        <div className="container mx-auto py-10 px-10">
          <div className="flex justify-between md:flex-row flex-col gap-10 items-center">
            {/* Logo & Address */}
            <div className="flex flex-col items-center text-center">
              <div className="mb-4">
                <img src={logo} alt="logo" className="w-20 h-20" />
              </div>
              {/* <div className="text-white space-y-2">
                <div className="flex items-center space-x-2">
                  <i className="fas fa-map-marker-alt text-teal-400"></i>
                  <p>123 Anywhere St, Any City 12345</p>
                </div>
                <div className="flex items-center space-x-2">
                  <i className="fas fa-phone-alt text-teal-400"></i>
                  <p>123-456-7890</p>
                </div>
                <div className="flex items-center space-x-2">
                  <i className="fas fa-envelope text-teal-400"></i>
                  <p>hello@callcenter.com</p>
                </div>
              </div> */}
            </div>

            {/* Links */}

            <ul className="space-y-2 text-white">
              <li>
                <a href="/" className="hover:underline">
                  Home{' '}
                </a>
              </li>
              <li>
                <Link to="/about" className="hover:underline">
                  About Us
                </Link>
              </li>
              <li>
                <a href="/" className="hover:underline">
                  Departments
                </a>
              </li>
              <li>
                <a href="/" className="hover:underline">
                  Doctors
                </a>
              </li>

              <li>
                <a href="/testimonial" className="hover:underline">
                  Testimonials
                </a>
              </li>
            </ul>
            <ul className="space-y-2 text-white">
              <li>
                <Link to="/blog" className="hover:underline">
                  Blog
                </Link>
              </li>
              <li>
                <a href="/contact" className="hover:underline">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  FAQs
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Terms and Conditions
                </a>
              </li>
            </ul>

            {/* Subscription */}
            <div className="text-white">
              <h3 className="font-semibold text-lg mb-2">Be Our Subscribers</h3>
              <p className="text-sm mb-4">
                To get the latest news about health from our experts.
              </p>
              <div className="flex">
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  className="px-4 py-2 rounded-l-md border-none focus:ring-0"
                />
                <button className="bg-customBlue text-white px-4 py-2 rounded-r-md">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="bg-naviblue  bg-opacity-50 py-4">
          <div className="container mx-auto px-4 flex justify-between items-center text-white">
            <p className="text-gray-400">
              &copy; 2024 Doctor Info Listing Center. All rights reserved. @
              <a
                href="https://r4healthcare.in/"
                target="_blank"
                rel="noreferrer"
              >
                R4 Healthcare
              </a>
            </p>
            <div className="space-x-4">
              <a href="#" className="hover:text-teal-400">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="hover:text-teal-400">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="hover:text-teal-400">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
